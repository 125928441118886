import React, { Component } from "react";
import styled from "styled-components";
import Lightbox from "react-images";
import AppConfig from "../../Config";
import Constants from "../../Domain/constants";
import Image from "../../Components/Image";

const startAsHttpPattern = /^(http|https):\/\//;

class ImageLightbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxIsOpen: false,
      currentImage: 0
    };
  }

  _openLightbox = (index = 0) => {
    this.setState({
      lightboxIsOpen: true,
      currentImage: index
    });
  };

  _closeLightbox = () => {
    this.setState({ lightboxIsOpen: false });
  };

  _gotoPrevious = () => {
    this.setState({ currentImage: this.state.currentImage - 1 });
    console.log("prev");
  };

  _gotoNext = () => {
    this.setState({ currentImage: this.state.currentImage + 1 });
    console.log("next");
  };

  _gotoImage = index => {
    this.setState({ currentImage: index });
  };

  _getFullImageUrl = images => {
    let host = Constants.mediaHost;
    let imagesFullUrl = images.map((d, i) => {
      if (d.image.match(startAsHttpPattern)) {
        return { src: `${d.image}` };
      } else if (d.image.indexOf("/") === 0) {
        return { src: `${host}${d.image}` };
      } else {
        return { src: `${host}/${d.image}` };
      }
    });

    return imagesFullUrl;
  };

  render() {
    let {
      imagesArray,
      css,
      showThumbnail = true,
      centerImage = false
    } = this.props;

    return (
      <Wrapper css={css}>
        {imagesArray.length !== 0
          ? showThumbnail && (
              <div
                className={`thumbnails-wrapper${centerImage ? " center" : ""}`}
              >
                {imagesArray &&
                  imagesArray.map((d, i) => {
                    return (
                      <Image
                        background
                        key={i}
                        src={d.image}
                        css={`
                          cursor: pointer;
                          flex-shrink: 0;
                          margin: 5px 2.5px;
                          :first-child {
                            margin-left: 0px;
                          }
                          :last-child {
                            margin-right: 0px;
                          }
                          ${AppConfig["Components/Lightbox"].thumbnailCss};
                        `}
                        onClick={() => this._openLightbox(i)}
                      />
                    );
                  })}
              </div>
            )
          : null}
        <Lightbox
          currentImage={this.state.currentImage}
          images={this._getFullImageUrl(imagesArray)}
          isOpen={this.state.lightboxIsOpen}
          onClickPrev={this._gotoPrevious}
          onClickNext={this._gotoNext}
          onClose={this._closeLightbox}
          showThumbnails={true}
          onClickThumbnail={this._gotoImage}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  & > .thumbnails-wrapper {
    display: flex;
    width: 100%;
    overflow: auto;
    justify-content: flex-start;
  }

  & > .thumbnails-wrapper.center {
    justify-content: center;
  }
  ${props => props.css}
  ${AppConfig["Components/Lightbox"].css};
`;

export default ImageLightbox;
