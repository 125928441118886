import React from "react";
import styled from "styled-components";
import * as Widget from "../Widget";
import CmsView from "../CmsView";

const TabWrapper = styled.div`
  & .tab-header {
    flex: 1;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ccc;

    & > .tab-item {
      border-left: 1px solid #ccc;
      border-bottom: 0px;
      border-right: 1px solid #ccc;
      border-top: 1px solid #ccc;
      margin: 0px;
      width: 120px;
      height: 50px;
      background-color: white;
      font-size: 14px;
      color: #777777;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .tab-item.active {
      background-color: #f0f0f0;
    }
  }
  & .tab-content {
  }
`;

const TAB_TYPES = {
  DETAIL: "DETAIL",
  INFO: "INFO"
};

class ProductTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: TAB_TYPES.DETAIL
    };
  }

  render() {
    let { selectedTab } = this.state;
    let { item } = this.props;

    return (
      <TabWrapper>
        <div className="tab-header">
          {[
            { display: "商品介紹", value: TAB_TYPES.DETAIL },
            { display: "商品資訊", value: TAB_TYPES.INFO }
          ].map((t, idx) => (
            <div
              className={`tab-item${t.value === selectedTab ? " active" : ""}`}
              key={idx}
              onClick={() => this.setState({ selectedTab: t.value })}
            >
              {t.display}
            </div>
          ))}
        </div>
        <div className="tab-content">
          {(() => {
            if (!item) {
              return (
                <Widget.Row
                  justify="center"
                  align="center"
                  css="padding: 20px 10px;"
                >
                  <Widget.Spinner />
                </Widget.Row>
              );
            }
            switch (selectedTab) {
              case TAB_TYPES.DETAIL:
                return <CmsView cmsContent={item.content} />;
              case TAB_TYPES.INFO:
                return <CmsView cmsContent={item.info} />;
            }
          })()}
        </div>
      </TabWrapper>
    );
  }
}

export default ProductTabs;
