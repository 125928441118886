import React, { Component } from "react";
import styled from "styled-components";
import Lightbox from "../Lightbox";
import Image from "../Image";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

class ProductImages extends Component {
  render() {
    let { images = [] } = this.props;
    if (images.length === 0) {
      return (
        <Wrapper>
          <Image img={""} />
        </Wrapper>
      );
    }
    return (
      <Wrapper>
        <Image background src={images[0].src} />
        <Lightbox
          imagesArray={images}
          css={`
            width: 100%;
          `}
        />
      </Wrapper>
    );
  }
}

export default ProductImages;
